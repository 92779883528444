var render = function render(){var _vm=this,_c=_vm._self._c;return _c('ul',{staticClass:"reservation-list-item"},[_c('li',{staticClass:"reservation-list-item-column patient_name"},_vm._l((_vm.reservation.patientsNames),function(name,index){return _c('div',{key:index},[_vm._v(" "+_vm._s(_vm.formatName(name))+" ")])}),0),_c('li',{staticClass:"reservation-list-item-column purpose"},[_vm._v(" "+_vm._s(_vm.displayReservationPurpose)+" ")]),_c('li',{staticClass:"reservation-list-item-column date"},[_vm._v(" "+_vm._s(_vm.displayDate)),_c('br',{staticClass:"br-time"}),_vm._v(_vm._s(_vm.displayStartTime)+" ")]),(_vm.netAvailableFlg || _vm.cancelableFlg)?_c('li',{staticClass:"reservation-list-item-column button-area"},[(_vm.reservation.requestFlg)?_c('div',{staticClass:"reservation-request-pending"},[_vm._v(" "+_vm._s(_vm.$t('parts.reservationRequestPending'))+" ")]):_vm._e(),(
        _vm.cancelableFlg &&
          !_vm.reservation.requestFlg &&
          _vm.isAvailableChangeCancelReservation
      )?[_c('div',{staticClass:"reservation-button-area change"},[(
            _vm.netAvailableFlg &&
              !_vm.includesDelSpecies(_vm.reservation.patientSpeciesIds) &&
              _vm.reservation.medicalRecordOriginalId === 0
          )?_c('reservation-list-item-button-change',{on:{"click":_vm.clickChange}}):_vm._e(),(
            _vm.includesDelSpecies(_vm.reservation.patientSpeciesIds) ||
              _vm.reservation.medicalRecordOriginalId !== 0
          )?_c('div',{staticClass:"text"},[_vm._v(" "+_vm._s(_vm.$t('parts.reservationChangeContactClinic'))+" ")]):_vm._e()],1),_c('div',{staticClass:"reservation-button-area cancel"},[(_vm.reservation.medicalRecordOriginalId === 0)?_c('reservation-list-item-button-cancel',{staticClass:"cancel-button",on:{"click":_vm.clickCancel}}):_vm._e()],1)]:_vm._e()],2):_vm._e()])
}
var staticRenderFns = []

export { render, staticRenderFns }
<template>
  <div class="reservation-list-item-button-cancel">
    <button
      class="reservation-list-item-button-cancel-class"
      type="button"
      @click="handleClick"
    >
      <span class="reservation-list-item-button-cancel-text">{{
        $t('common.cancel')
      }}</span>
    </button>
  </div>
</template>

<script>
export default {
  name: 'ReservationListItemButtonCancel',

  methods: {
    handleClick() {
      this.$emit('click')
    }
  }
}
</script>

<style lang="scss" scoped>
.reservation-list-item-button-cancel {
  @include selector-width(
    '.reservation-list-item-button-cancel-class',
    155px,
    176px
  );
  > .reservation-list-item-button-cancel-class {
    height: 40px;
    border-radius: 6px;
    background-color: #{$plus_light_orange};
    border: none;
    padding: 0;
    cursor: pointer;
    &:hover {
      opacity: 0.8;
    }
    &:active {
      margin: 2px auto 0 auto;
      height: 38px;
    }
    > .reservation-list-item-button-cancel-text {
      height: 27px;
      @include font-size(18);
      font-weight: bold;
      line-height: 1.5;
      color: #{$plus_white};
    }
  }
}
</style>

<template>
  <ul class="reservation-list-item-not-button">
    <li class="reservation-list-item-column patient-name">
      <div v-for="(name, index) in reservation.patientsNames" :key="index">
        {{ formatName(name) }}
      </div>
    </li>
    <li class="reservation-list-item-column purpose">
      {{ displayReservationPurpose }}
    </li>
    <li class="reservation-list-item-column date">
      {{ displayDate }}<br class="br-time" />{{ displayStartTime }}
    </li>
  </ul>
</template>

<script>
import {
  localizeValue,
  formatDate,
  formatStartTime,
  formatName
} from '@/utils/reservation_format'
import moment from 'moment'
moment.locale('ja')

export default {
  name: 'ReservationListItemNotButton',

  props: {
    reservation: {
      patientsNames: {
        type: Array,
        validator: arr => {
          return arr.every(v => typeof v === 'string')
        }
      },
      reservationPurposeName: { type: String },
      date: { type: String },
      startTime: { type: String }
    }
  },

  computed: {
    displayReservationPurpose() {
      const {
        reservationPurposeName,
        reservationPurposeNameEnglish
      } = this.reservation
      return localizeValue(
        reservationPurposeName,
        reservationPurposeNameEnglish,
        this.$i18n.locale
      )
    },
    displayDate() {
      return formatDate(this.reservation.date, this.$i18n.locale)
    },
    displayStartTime() {
      return formatStartTime(this.reservation.startTime, this.$i18n.locale)
    }
  },
  methods: {
    formatName(name) {
      return formatName(name, this.$i18n.locale)
    }
  }
}
</script>

<style lang="scss" scoped>
.reservation-list-item-not-button {
  display: table;
  margin: 0 auto;
  padding: 0;
  border-collapse: collapse;
  box-sizing: border-box;
  margin-bottom: -2px;
  width: 960px;
  > .reservation-list-item-column {
    display: table-cell;
    vertical-align: middle;
    height: 150px;
    border: 2px solid #{$plus_7c_gray};
    box-sizing: border-box;
    font-weight: bold;
    &.patient-name {
      width: 30%;
    }
    &.purpose {
      width: 28%;
    }
    &.date {
      width: 42%;
    }
  }
}
@media (min-width: 961px) {
  .reservation-list-item-column {
    &.date {
      > .br-time {
        display: none;
      }
    }
  }
}
@media (max-width: 960px) {
  .reservation-list-item-not-button {
    width: 640px;
  }
}
@media (max-width: 640px) {
  @include selector-width(
    '.reservation-list-item-not-button',
    620px,
    640px,
    ''
  );
  .reservation-list-item-not-button {
    > .reservation-list-item-column {
      font-size: calc(100vw / 36);
      &.patient-name {
        width: 30%;
      }
      &.purpose {
        width: 30%;
      }
      &.date {
        width: 40%;
      }
    }
  }
}
</style>

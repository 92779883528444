<template>
  <ul class="reservation-list-item">
    <li class="reservation-list-item-column patient_name">
      <div v-for="(name, index) in reservation.patientsNames" :key="index">
        {{ formatName(name) }}
      </div>
    </li>
    <li class="reservation-list-item-column purpose">
      {{ displayReservationPurpose }}
    </li>
    <li class="reservation-list-item-column date">
      {{ displayDate }}<br class="br-time" />{{ displayStartTime }}
    </li>
    <li
      v-if="netAvailableFlg || cancelableFlg"
      class="reservation-list-item-column button-area"
    >
      <div v-if="reservation.requestFlg" class="reservation-request-pending">
        {{ $t('parts.reservationRequestPending') }}
      </div>
      <template
        v-if="
          cancelableFlg &&
            !reservation.requestFlg &&
            isAvailableChangeCancelReservation
        "
      >
        <div class="reservation-button-area change">
          <reservation-list-item-button-change
            v-if="
              netAvailableFlg &&
                !includesDelSpecies(reservation.patientSpeciesIds) &&
                reservation.medicalRecordOriginalId === 0
            "
            @click="clickChange"
          />
          <div
            class="text"
            v-if="
              includesDelSpecies(reservation.patientSpeciesIds) ||
                reservation.medicalRecordOriginalId !== 0
            "
          >
            {{ $t('parts.reservationChangeContactClinic') }}
          </div>
        </div>
        <div class="reservation-button-area cancel">
          <reservation-list-item-button-cancel
            class="cancel-button"
            v-if="reservation.medicalRecordOriginalId === 0"
            @click="clickCancel"
          />
        </div>
      </template>
    </li>
  </ul>
</template>

<script>
import ReservationListItemButtonChange from '@/components/parts/atoms/ReservationListItemButtonChange.vue'
import ReservationListItemButtonCancel from '@/components/parts/atoms/ReservationListItemButtonCancel.vue'
import {
  localizeValue,
  formatDate,
  formatStartTime,
  formatName
} from '@/utils/reservation_format'
import moment from 'moment'
import { mapGetters } from 'vuex'
moment.locale('ja')

export default {
  name: 'ReservationListItem',

  components: {
    ReservationListItemButtonChange,
    ReservationListItemButtonCancel
  },

  props: {
    reservation: {
      ids: { type: Array },
      patientsIds: { type: Array },
      patientsNames: { type: Array },
      patientSpeciesIds: { type: Array },
      reservationPurposeId: { type: String },
      reservationPurposeName: { type: String },
      date: { type: String },
      startTime: { type: String },
      requestFlg: { type: Boolean }
    },
    netAvailableFlg: { type: Number },
    cancelableFlg: { type: Number },
    reservationChangeCancelAvailablePeriod: { type: Number }
  },

  computed: {
    ...mapGetters({
      species: 'species/getDataIncludeDelData'
    }),
    isAvailableChangeCancelReservation() {
      return moment().isBefore(
        moment(this.reservation.date).subtract(
          this.reservationChangeCancelAvailablePeriod - 1,
          'd'
        )
      )
    },
    displayReservationPurpose() {
      const {
        reservationPurposeName,
        reservationPurposeNameEnglish
      } = this.reservation
      return localizeValue(
        reservationPurposeName,
        reservationPurposeNameEnglish,
        this.$i18n.locale
      )
    },
    displayDate() {
      return formatDate(this.reservation.date, this.$i18n.locale)
    },
    displayStartTime() {
      return formatStartTime(this.reservation.startTime, this.$i18n.locale)
    }
  },
  methods: {
    formatName(name) {
      return formatName(name, this.$i18n.locale)
    },
    clickChange() {
      this.$emit('change-click', this.reservation)
    },
    clickCancel() {
      this.$emit('cancel-click')
    },
    includesDelSpecies(speciesIds) {
      const delFlgs = speciesIds.map(speciesId => {
        const tmpSpecies = this.species.find(v => v.id === speciesId)
        return tmpSpecies ? tmpSpecies.delFlg : ''
      })
      return delFlgs.includes(1)
    }
  }
}
</script>

<style lang="scss" scoped>
.reservation-list-item {
  display: table;
  margin: 0 auto;
  padding: 0;
  border-collapse: collapse;
  box-sizing: border-box;
  width: 960px;
  word-break: break-word;
  > .reservation-list-item-column {
    display: table-cell;
    vertical-align: middle;
    height: 150px;
    border: 2px solid #{$plus_7c_gray};
    box-sizing: border-box;
    font-weight: bold;
    &.patient_name {
      width: 16%;
    }
    &.purpose {
      width: 17%;
    }
    &.date {
      width: 42%;
    }
    &.button-area {
      width: 25%;
      > .reservation-button-area {
        display: flex;
        flex-direction: column;
        justify-content: center;
        > .text {
          padding: 10px;
        }
        > .cancel-button {
          margin: 10px 0px;
        }
      }
    }
  }
}
@media (min-width: 961px) {
  .reservation-list-item-column {
    &.date {
      > .br-time {
        display: none;
      }
    }
  }
}
@media (max-width: 960px) {
  .reservation-list-item {
    width: 640px;
  }
}
@media (max-width: 640px) {
  @include selector-width('.reservation-list-item', 620px, 640px, '');
  .reservation-list-item {
    > .reservation-list-item-column {
      font-size: calc(100vw / 36);
      &.patient_name {
        width: 17%;
      }
      &.purpose {
        width: 17%;
      }
      &.date {
        width: 37%;
      }
      &.button-area {
        width: 29%;
      }
    }
  }
}
</style>

<template>
  <div class="reservation-confirm">
    <div class="page-title">
      <base-page-title>{{
        $t('reservationConfirm.pageTitle')
      }}</base-page-title>
    </div>
    <div class="reservation-list" v-if="!cancelConfirmationFlg">
      <reservation-list
        :reservations="bodyData"
        :patients="patients"
        :reservationPurposes="reservationPurposes"
        @change-click="changeReservation"
        @cancel-click="cancelReservation"
      />
    </div>
    <div class="back-button">
      <base-cancel-button @click="backReservationMenu">{{
        $t('common.buttonReturn')
      }}</base-cancel-button>
    </div>
    <div class="cancel-confirmation-layer" v-if="cancelConfirmationFlg">
      <reservation-confirm-cancel
        :reservation="confirmReservation"
        @not-cancel="notCancel"
        @decide-cancel="decideCancel"
      />
    </div>
    <announce-popup
      v-if="alertFlg"
      @close="closeAlert"
      :title="$t('common.error')"
      :buttons="buttons"
      :type="'failure'"
      >{{ popupMessage }}</announce-popup
    >
  </div>
</template>

<script>
import BasePageTitle from '@/components/parts/atoms/BasePageTitle.vue'
import ReservationList from '@/components/parts/organisms/ReservationList'
import BaseCancelButton from '@/components/parts/atoms/BaseCancelButton.vue'
import ReservationConfirmCancel from '@/components/parts/organisms/ReservationConfirmCancel'
import AnnouncePopup from '@/components/popups/AnnouncePopup'
import { mapGetters } from 'vuex'

export default {
  name: 'ReservationConfirm',
  components: {
    BasePageTitle,
    ReservationList,
    BaseCancelButton,
    ReservationConfirmCancel,
    AnnouncePopup
  },

  data: () => {
    return {
      cancelConfirmationFlg: false,
      confirmReservation: {},
      bodyData: [],
      alertFlg: false,
      popupMessage: '',
      buttons: []
    }
  },

  computed: {
    ...mapGetters({
      reservations: 'reservation/getReservations',
      patients: 'patient/getPatients',
      reservationPurposes: 'reservationPurpose/getReservationPurposes',
      reservationRequests: 'reservationRequests/getData'
    })
  },

  created() {
    const unfixedReservations = this.reservationRequests.filter(
      v => v.reservationId === 0
    )
    this.bodyData = unfixedReservations.concat(this.reservations)
  },

  mounted() {
    this.resetScenario()
  },

  methods: {
    resetScenario() {
      this.$store.dispatch('auth/scenarioReset')
      this.$store.dispatch('patient/resetNewPatients')
      this.$store.dispatch('reservation/resetChangeReservation')
    },
    changeReservation(reservation) {
      this.$store.dispatch('auth/changeReservationScenario')
      this.$store.dispatch('reservation/setChangeReservation', {
        ...reservation,
        memo: ''
        // ↑医院が編集した予約のメモを飼主から見れないようにするため、reservationのmemoの情報はフロント側に返していない。
        //  plus_netで飼主が予約を変更するときは備考の初期値を空白にする仕様になったので空文字を設定する。
      })
      this.$router.push({ name: 'ReservationPurpose' })
    },
    backReservationMenu() {
      this.$router.back()
    },
    notCancel() {
      this.cancelConfirmationFlg = false
      this.confirmReservation = {}
    },
    async decideCancel() {
      const result = await this.$store.dispatch(
        'reservation/cancelReservation',
        this.confirmReservation
      )
      if (result === true) {
        this.$router.push({ name: 'ReservationCancelComplete' })
        this.confirmReservation = {}
      } else {
        this.alertFlg = true
        if (result === 'not authenticated') {
          this.buttons = [this.$t('common.buttonToLogin')]
        } else if (result === 'not match data') {
          this.popupMessage = this.$t(
            'reservationConfirm.errorCancelUnavailable'
          )
          this.buttons = [this.$t('common.close')]
        } else {
          this.popupMessage = this.$t('reservationConfirm.errorCancelFailed')
          this.buttons = [this.$t('common.close')]
        }
      }
    },
    closeAlert() {
      this.alertFlg = false
      this.cancelConfirmationFlg = false
    },
    cancelReservation(reservation) {
      this.confirmReservation = reservation
      this.cancelConfirmationFlg = true
    }
  }
}
</script>

<style lang="scss" scoped>
.reservation-confirm {
  > .page-title {
    margin-top: 69px;
  }
  > .reservation-list {
    margin-top: 105px;
  }
  > .back-button {
    margin: 86.5px 0;
  }
  > .cancel-confirmation-layer {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: auto;
    min-height: 100%;
    z-index: 200;
    background: rgba(204, 204, 204, 0.5);
  }
  @media (max-width: $global-container) {
    > .reservation-confirm {
      > .page-title {
        margin-top: 132px;
      }
      > .reservation-list {
        margin-top: 57px;
      }
      > .back-button {
        margin: 133.5px 0;
      }
    }
  }
}
</style>

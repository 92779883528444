<template>
  <div class="reservation-confirm-cancel">
    <div class="message-area">
      <div class="confirm-message">
        {{ $t('parts.cancelConfirmMessage') }}
      </div>
    </div>
    <div class="reservation-list-area">
      <div class="header-area">
        <ul class="reservation-list-header">
          <li class="reservation-list-header-column patient-name">
            {{ $t('common.labelPetName') }}
          </li>
          <li class="reservation-list-header-column purpose">
            {{ $t('common.labelPurposeOfVisit') }}
          </li>
          <li class="reservation-list-header-column date">
            {{ $t('common.reservationDate') }}
          </li>
        </ul>
      </div>
      <div class="list-area">
        <reservation-list-item-not-button :reservation="reservation" />
      </div>
    </div>
    <div class="button-area">
      <div class="button-area-cancel">
        <base-decision-button @click="decideCancel" :disabled="waitFlg">{{
          $t('common.cancel')
        }}</base-decision-button>
      </div>
      <div class="button-area-back">
        <base-cancel-button @click="notCancel">{{
          $t('common.buttonReturn')
        }}</base-cancel-button>
      </div>
    </div>
  </div>
</template>

<script>
import ReservationListItemNotButton from '@/components/parts/atoms/ReservationListItemNotButton'
import BaseDecisionButton from '@/components/parts/atoms/BaseDecisionButton'
import BaseCancelButton from '@/components/parts/atoms/BaseCancelButton'

export default {
  name: 'ReservationConfirmCancel',

  components: {
    ReservationListItemNotButton,
    BaseDecisionButton,
    BaseCancelButton
  },

  props: {
    reservation: { type: Object }
  },

  data() {
    return { waitFlg: false }
  },

  methods: {
    decideCancel() {
      this.waitFlg = true
      this.$emit('decide-cancel', this.reservation)
    },
    notCancel() {
      this.$emit('not-cancel')
    }
  }
}
</script>

<style lang="scss" scoped>
.reservation-confirm-cancel {
  width: 1001px;
  height: 100%;
  margin-top: 120px;
  box-shadow: 0 1px 0 0 $plus_bc_gray;
  background: #{$plus_white};
  display: block;
  padding-top: 77px;
  padding-bottom: 77px;
  margin: 80px auto;
  word-break: break-word;
  > .message-area {
    margin-bottom: 51.5px;
    > .confirm-message {
      width: 458px;
      height: 29px;
      font-size: 18px;
      font-weight: bold;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.5;
      letter-spacing: normal;
      color: $plus_black;
      margin: 0 auto;
    }
  }
  > .reservation-list-area {
    margin-bottom: 67.5px;
    > .header-area {
      > .reservation-list-header {
        display: table;
        margin: 0 auto;
        padding: 0;
        border-collapse: collapse;
        box-sizing: border-box;
        margin-bottom: -2px;
        width: 960px;
        > .reservation-list-header-column {
          display: table-cell;
          vertical-align: middle;
          height: 45px;
          background-color: #{$plus_7c_gray};
          border: 2px solid #{$plus_7c_gray};
          box-sizing: border-box;
          font-weight: bold;
          color: #ffffff;
          &.patient-name {
            width: 30%;
          }
          &.purpose {
            width: 28%;
          }
          &.date {
            width: 42%;
          }
        }
      }
    }
  }
  > .button-area {
    .button-area-cancel {
      margin-bottom: 33px;
    }
  }
}
@media (max-width: 960px) {
  .reservation-confirm-cancel {
    width: auto;
    > .reservation-list-area {
      > .header-area {
        > .reservation-list-header {
          width: 640px;
        }
      }
    }
  }
}
@media (max-width: 640px) {
  .reservation-confirm-cancel {
    width: auto;
    > .message-area {
      > .confirm-message {
        width: auto;
        font-size: calc(100vw / 36);
      }
    }
    > .reservation-list-area {
      > .header-area {
        @include selector-width('.reservation-list-header', 620px, 640px);
        > .reservation-list-header {
          > .reservation-list-header-column {
            font-size: calc(100vw / 36);
            &.patient-name {
              width: 30%;
            }
            &.purpose {
              width: 30%;
            }
            &.date {
              width: 40%;
            }
          }
        }
      }
    }
  }
}
</style>
